import { type RouteRecordRaw } from 'vue-router';
import { LOGIN_NAME } from '@/router/constant';
const urlParams = new URLSearchParams(window.location.href);
/**
 * layout布局之外的路由
 */
export const LoginRoute: RouteRecordRaw = {
  path: '/login',
  name: LOGIN_NAME,
  component: () => import(/* webpackChunkName: "login" */ '@/views/login/index.vue'),
  meta: {
    title: '登录',
  },
};

export const ConsoleRoute: RouteRecordRaw = {
  path: '/node/console',
  name: `web-console`,
  component: () =>
    import(/* webpackChunkName: "node-web-console" */ '@/views/tenant/shellConsole.vue'),

  meta: {
    title: urlParams.get('deviceName') + ' - Web Console',
  },
};

export default [LoginRoute, ConsoleRoute];
