import axios, { AxiosRequestConfig } from 'axios';
import { message as $message } from 'ant-design-vue';
import fileDownload from 'js-file-download';
import { ACCESS_TOKEN_KEY } from '@/enums/cacheEnum';
import { Storage } from '@/utils/Storage';
import { useUserStore } from '@/store/modules/user';
// import { getI18n } from '@/locales';
import errno from '@/api/errno';
// import {ExclamationCircleOutlined} from '@ant-design/icons'
import { useLocaleStore } from '@/store/modules/locale';
// import { resetRouter } from '@/router';

export interface RequestOptions {
  /** 当前接口权限, 不需要鉴权的接口请忽略， 格式：sys:user:add */
  permCode?: string;
  /** 是否直接获取data，而忽略message等 */
  isGetDataDirectly?: boolean;
  /** 请求成功是提示信息 */
  successMsg?: string;
  /** 请求失败是提示信息 */
  errorMsg?: string;
  /** 是否mock数据请求 */
  isMock?: boolean;
  /** 是否为文件需要下载 */
  isFile?: boolean;
  /** 下载文件的名称 */
  fileName?: string;
}

const localeStore = useLocaleStore();
const locale = localeStore.getLocale as string;

const UNKNOWN_ERROR = errno[locale]['UNKNOWN_ERROR'];

/** 真实请求的路径前缀 */
const baseApiUrl = import.meta.env.VITE_BASE_API;
/** mock请求路径前缀 */
const baseMockUrl = import.meta.env.VITE_MOCK_API;

const service = axios.create({
  // baseURL: baseApiUrl,
  timeout: 60000,
});

service.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const token = Storage.get(ACCESS_TOKEN_KEY);
    if (token && config.headers) {
      // 请求头token信息，请根据实际情况进行修改
      config.headers['Authorization'] = token;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

service.interceptors.response.use(
  (response) => {
    const res = response.data;
    const config = response.config;
    const userStore = useUserStore();
    // Check if response's type is application/octet-stream, please download file
    if (response.headers['content-type'] === 'application/octet-stream') {
      // check if Esix-File-Name header is set
      if (response.headers['esix-file-name']) {
        const fileName = response.headers['esix-file-name'];
        fileDownload(res, fileName);
      } else {
        fileDownload(res, `logs.${config.params.export_format || 'csv'}`);
      }
      return Promise.resolve(res);
    }

    // Check if meeting acs response
    if (res.result !== undefined && res.result !== null && res.result !== '') {
      if (res.result === 'success') {
        return Promise.resolve(res);
      }
      // Handle device which is not connected to SDM
      if (res.result === 'fail' && res.msg === 'not exists') {
        return Promise.resolve(res);
      }
    }

    // if the custom code is not 200, it is judged as an error.
    if (res.code !== 200) {
      res.reason !== '' && Object.keys(errno.i18n).includes(res.reason)
        ? $message.error(errno[locale][res.reason])
        : $message.error(res.message || UNKNOWN_ERROR);

      // Illegal token
      if (res.code === 403) {
        userStore.resetToken();
        window.localStorage.clear();
        // window.location.reload();
        // resetRouter();
        // to re-login
        // Modal.confirm({
        //   title: '警告',
        //   content: res.message || '账号异常，您可以取消停留在该页上，或重新登录',
        //   okText: '重新登录',
        //   cancelText: '取消',
        //   onOk: () => {
        //     userStore.resetToken();
        //     localStorage.clear();
        //     window.location.reload();
        //   },
        // });
      }

      // throw other
      const error = new Error(res.message || UNKNOWN_ERROR) as Error & { code: any; reason: any };
      error.code = res.code;
      error.reason = res.reason;
      return Promise.reject(error);
    } else {
      // $message.success(tc('common.done'));
      return res;
    }
  },
  (error) => {
    // 处理 422 或者 500 的错误异常提示
    const userStore = useUserStore();
    let errMsg = '';
    const reason = error?.response?.data?.reason;
    const status = error?.response?.status;

    // 403 Auth handler
    if (status == 403 && reason == 'TOKEN_EXPIRED') {
      errMsg = errno[locale]['TOKEN_EXPIRED'];
      $message.error(errMsg, 1).then(() => {
        userStore.resetToken();
        window.localStorage.clear();
        window.location.reload();
      });
      // resetRouter();
      return Promise.reject(error);
    }

    // Default handler
    if (reason !== '' && Object.keys(errno.i18n).includes(reason)) {
      errMsg = errno[locale][reason];
    } else {
      errMsg = error?.response?.data?.message ?? acsRespHandler(error) ?? UNKNOWN_ERROR;
    }
    $message.error(errMsg);
    error.message = errMsg;
    return Promise.reject(error);
  },
);

export type Response<T = any> = {
  code: number;
  message: string;
  data: T;
};

export type BaseResponse<T = any> = Promise<Response<T>>;

/**
 *
 * @param method - request methods
 * @param url - request url
 * @param data - request data or params
 */
export const request = async <T = any>(
  config: AxiosRequestConfig,
  options: RequestOptions = {},
): Promise<T> => {
  try {
    const { successMsg, errorMsg, permCode, isMock, isGetDataDirectly = true } = options;
    // 如果当前是需要鉴权的接口 并且没有权限的话 则终止请求发起
    if (permCode && !useUserStore().perms.includes(permCode)) {
      return $message.error('error.commonError.user.accessDenied');
    }
    const fullUrl = `${(isMock ? baseMockUrl : baseApiUrl) + config.url}`;
    // config.url = fullUrl.replace(new RegExp('(?<!:)\\/{2,}', 'g'), '/');
    config.url = fullUrl;
    const res = await service.request(config);
    successMsg && $message.success(successMsg);
    errorMsg && $message.error(errorMsg);
    return isGetDataDirectly ? res.data : res;
  } catch (error: any) {
    return Promise.reject(error);
  }
};

const acsRespHandler = (error: any): string => {
  const res = error?.response?.data?.result;
  const errCode = error?.response?.data?.code;
  const msg = error?.response?.data?.msg;
  const faultMsg = error?.response?.data?.faultMsg;
  if (res !== '') {
    if (res !== 'success') {
      return `[ACS ${errCode} ${msg}] ${faultMsg}`;
    }
  }
  return '';
};
