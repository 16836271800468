/**
 * system module
 */
export default {
  'views/tenant': () => import('@/views/tenant/index.vue'),
  // 'views/tenant/tenantDetails': () => import('@/views/tenant/tenantDetails.vue'),
  'views/tenant/deviceDetails': () => import('@/views/tenant/deviceDetails.vue'),
  'views/tenant/deviceMgnt': () => import('@/views/tenant/deviceMgnt.vue'),
  'views/tenant/tagMgnt': () => import('@/views/tenant/tagMgnt.vue'),
  'views/tenant/tunnelMgnt': () => import('@/views/tenant/tunnelMgnt.vue'),
};
